/* animations.css */

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.h2-slide-in {
  animation: slideIn 1s forwards;
}

.p-slide-in {
  animation: slideIn 1s forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.hidden-initially {
  opacity: 0;
}


@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-from-bottom {
  animation: slideInFromBottom 1s forwards;
}

.opacity-0 {
  opacity: 0;
}

/* animations.css */

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 1s forwards;
}

.opacity-0 {
  opacity: 0;
}


